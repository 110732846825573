import React from 'react'
import {Route,Routes ,Outlet,Link } from 'react-router-dom'

function AboutComponent() {
  return (
    <Routes>
    </Routes>
  )
}

export default AboutComponent