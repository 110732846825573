import React from 'react'
import Nav from '../components/Nav'
import { Outlet } from 'react-router-dom'

function Home() {
  return (
    <div>
      
    <h1>WELCOME TO HOME PAGE
    
  </h1>
      
      </div>
  )
}

export default Home